import React, { Component } from 'react'
import Header from '../Header'
import HeaderMobile from '../HeaderMobile'
import Helmet from '../Helmet'
import Bounce from 'react-reveal/Bounce'
import Fade from 'react-reveal/Fade'

class Borsalino_du_Jal extends Component {
  
    render () {
        return (
            <div>
                <Helmet title={"Borsalino du Jal Chevaux"}
                        link={"http://chevaux.guillaumequeste.fr/borsalino_du_jal"} />
                <Header />
                <HeaderMobile />
                <div className="accueilPremierPartantGalop">
                    <Bounce top>
                        <h5 className="titrePage">Borsalino du Kal</h5>
                        <p className="infosPage">20 courses</p>
                        <p className="infosPage">4 victoires</p>
                        <p className="infosPage">5 places</p>
                    </Bounce>
                    <div className="photosPage">
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/2014/borsalino_du_jal/borsalino01.jpg")} alt="borsalino01" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/2014/borsalino_du_jal/borsalino02.jpg")} alt="borsalino02" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/2014/borsalino_du_jal/borsalino03.jpg")} alt="borsalino03" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                    </div>
                    <div className="photosPage">
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/2014/borsalino_du_jal/borsalino04.jpg")} alt="borsalino04" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/2014/borsalino_du_jal/borsalino05.jpg")} alt="borsalino05" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/2014/borsalino_du_jal/borsalino06.jpg")} alt="borsalino06" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                    </div>
                    <div className="photosPage">
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/2014/borsalino_du_jal/borsalino07.jpg")} alt="borsalino07" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/2014/borsalino_du_jal/borsalino08.jpg")} alt="borsalino08" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/2014/borsalino_du_jal/borsalino09.jpg")} alt="borsalino09" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                    </div>
                    <div className="photosPage">
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/2014/borsalino_du_jal/borsalino10.jpg")} alt="borsalino10" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/2014/borsalino_du_jal/borsalino11.jpg")} alt="borsalino11" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/2014/borsalino_du_jal/borsalino12.jpg")} alt="borsalino12" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                    </div>
                    <div className="photosPage">
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/2014/borsalino_du_jal/borsalino13.jpg")} alt="borsalino13" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/2014/borsalino_du_jal/borsalino14.jpg")} alt="borsalino14" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/2014/borsalino_du_jal/borsalino15.jpg")} alt="borsalino15" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                    </div>
                    <div className="photosPage">
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/2014/borsalino_du_jal/borsalino16.jpg")} alt="borsalino16" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/2014/borsalino_du_jal/borsalino17.jpg")} alt="borsalino17" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        )
    }
}

export default Borsalino_du_Jal