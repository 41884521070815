import React, { Component } from 'react'
import Helmet from '../Helmet'
import Bounce from 'react-reveal/Bounce'
import Fade from 'react-reveal/Fade'
import { Link } from "react-router-dom";

class C10032024 extends Component {
  
    render () {
        return (
            <div>
                <Helmet title={"Kaleotic 10 mars 2024"}
                        link={"http://chevaux.guillaumequeste.fr/Kaleotic_10032024"} />
        
                <div className="accueilPremierPartantGalop2">
                    <Bounce top>
                        <h3 className="titreKaleoticCourses">10 mars 2024</h3>
                        <h5 className="titreKaleoticCourses2">Saint-Brieuc</h5>
                        <h5 className="titreKaleoticCourses2">Prix de Clairefontaine</h5>
                        <h5 className="titreKaleoticCourses2">A conditions</h5>
                        <h5 className="titreKaleoticCourses2">Classe 3</h5>
                        <h5 className="titreKaleoticCourses2">3 800m Steeple-chase</h5>
                        <h5 className="titreKaleoticCourses2">6 partants</h5>
                        <h5 className="titreKaleoticCourses3">5ème</h5>
                    </Bounce>

                    <Link to="/" className="accueilApp">
                        <img
                            src={require("../../img/horse.png")}
                            alt="horse"
                            className="horseLady"
                        />
                    </Link>

                    <Link to="/kaleotic_courses" className="retour">
                        <span className="retour">Retour</span>
                    </Link>

                    <div className="photosPage">
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/kaleotic/courses/10032024/10032024_01.jpg")} alt="10032024_01" className="photoPageVerticale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/kaleotic/courses/10032024/10032024_02.jpg")} alt="10032024_02" className="photoPageVerticale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/kaleotic/courses/10032024/10032024_03.jpg")} alt="10032024_03" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                    </div>
                    <div className="photosPage">
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/kaleotic/courses/10032024/10032024_04.jpg")} alt="10032024_04" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/kaleotic/courses/10032024/10032024_05.jpg")} alt="10032024_05" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/kaleotic/courses/10032024/10032024_06.jpg")} alt="10032024_06" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                    </div>
                    <div className="photosPage">
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/kaleotic/courses/10032024/10032024_07.jpg")} alt="10032024_07" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/kaleotic/courses/10032024/10032024_08.jpg")} alt="10032024_08" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/kaleotic/courses/10032024/10032024_09.jpg")} alt="10032024_09" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                    </div>
                    <div className="photosPage">
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/kaleotic/courses/10032024/10032024_10.jpg")} alt="10032024_10" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/kaleotic/courses/10032024/10032024_11.jpg")} alt="10032024_11" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/kaleotic/courses/10032024/10032024_12.jpg")} alt="10032024_12" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                    </div>
                    <div className="photosPage">
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/kaleotic/courses/10032024/10032024_13.jpg")} alt="10032024_13" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/kaleotic/courses/10032024/10032024_14.jpg")} alt="10032024_14" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/kaleotic/courses/10032024/10032024_15.jpg")} alt="10032024_15" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                    </div>
                    <div className="photosPage">
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/kaleotic/courses/10032024/10032024_16.jpg")} alt="10032024_16" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/kaleotic/courses/10032024/10032024_17.jpg")} alt="10032024_17" className="photoPageVerticale"/>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/kaleotic/courses/10032024/10032024_18.jpg")} alt="10032024_18" className="photoPageHorizontale"/>
                            </div>
                        </Fade>
                    </div>
                    <div className="photosPage">
                        <Fade right>
                            <div className="divPhotoPage">
                                <img src={require("../../img/kaleotic/courses/10032024/10032024_19.jpg")} alt="10032024_19" className="photoPageVerticale"/>
                            </div>
                        </Fade>
                    </div>
                   
                </div>
            </div>
        )
    }
}

export default C10032024