import React, { Component } from "react";
import Header from "../Header";
import HeaderMobile from "../HeaderMobile";
import Helmet from "../Helmet";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";

class Belle_Promesse_Listed extends Component {
  render() {
    return (
      <div>
        <Helmet
          title={"Belle Promesse victoire Listed Chevaux"}
          link={"http://chevaux.guillaumequeste.fr/belle_promesse_listed"}
        />
        <Header />
        <HeaderMobile />
        <div className="accueilPremierPartantGalop">
          <Bounce top>
            <h5 className="titrePage">Victoire de Belle Promesse dans une Listed</h5>
            <p className="infosPage">le 22 mai 2021 à Auteuil</p>
            <p className="infosPage">avec Pierre Dubourg</p>
            <p className="infosPage">Prix d'Arles</p>
          </Bounce>
          <div className="photosPage">
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed01.jpg")}
                  alt="belle_listed01"
                  className="photoPageVerticale"
                />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed02.jpg")}
                  alt="belle_listed02"
                  className="photoPageVerticale"
                />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed03.jpg")}
                  alt="belle_listed03"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
          </div>
          <div className="photosPage">
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed04.jpg")}
                  alt="belle_listed04"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed05.jpg")}
                  alt="belle_listed05"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed06.jpg")}
                  alt="belle_listed06"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
          </div>
          <div className="photosPage">
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed07.jpg")}
                  alt="belle_listed07"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed08.jpg")}
                  alt="belle_listed08"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed09.jpg")}
                  alt="belle_listed09"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
          </div>
          <div className="photosPage">
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed10.jpg")}
                  alt="belle_listed10"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed11.jpg")}
                  alt="belle_listed11"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed12.jpg")}
                  alt="belle_listed12"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
          </div>
          <div className="photosPage">
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed13.jpg")}
                  alt="belle_listed13"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed14.jpg")}
                  alt="belle_listed14"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed15.jpg")}
                  alt="belle_listed15"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
          </div>
          <div className="photosPage">
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed16.jpg")}
                  alt="belle_listed16"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed17.jpg")}
                  alt="belle_listed17"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed18.jpg")}
                  alt="belle_listed18"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
          </div>
          <div className="photosPage">
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed19.jpg")}
                  alt="belle_listed19"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed20.jpg")}
                  alt="belle_listed20"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed21.jpg")}
                  alt="belle_listed21"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
          </div>
          <div className="photosPage">
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed22.jpg")}
                  alt="belle_listed22"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed23.jpg")}
                  alt="belle_listed23"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed24.jpg")}
                  alt="belle_listed24"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
          </div>
          <div className="photosPage">
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed25.jpg")}
                  alt="belle_listed25"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img
                  src={require("../../img/xxl/belle_promesse_listed/belle_listed26.jpg")}
                  alt="belle_listed26"
                  className="photoPageHorizontale"
                />
              </div>
            </Fade>
          </div>
        </div>
      </div>
    );
  }
}

export default Belle_Promesse_Listed;
